@tailwind base;
@tailwind components;
@tailwind utilities;

/* CSS style for ROW 1 */
.bubs-header {
    @apply bg-header-bg dark:bg-header-bg rounded-xl  pr-3 pt-1 -mt-3 sticky top-0 align-top self-start z-50;
}

/* CSS Style for ROW 2 is as follows:
    1.  Claimed Devices Status
        - bubs
            > flexx-contnt-donut
            > flexx-contnt-donut-bottom
    2. Devices Recovery Status
        - bubs
            > flexx-contnt-donut
            > flexx-contnt-donut-bottom
    3. APIM Contact Stats
        - bubs
            > flexx-contnt-donut
            > flexx-contnt-donut-bottom
    4. Probable Causes
        - bubs-prob-cause    
*/
.bubs{
    height: 14.2rem;
    @apply relative grow shrink pt-5 bg-bubble-bg-l
    dark:bg-bubble-bg-d rounded-2xl w-auto sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6;
}
.flexx-contnt-donut {
    @apply relative flex flex-row justify-between w-auto sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 text-right h-auto -mt-2;
}

/* .flexx-contnt-donut.fullScreen {
    @apply w-full h-full absolute left-0 top-0 border-r-0;
} */

.flexx-contnt-donut-bottom {
    @apply relative flex flex-row justify-center px-7 -mt-16 w-auto h-auto ;
}
.bubs-prob-cause{
    @apply relative grow shrink flex flex-col justify-center gap-y-5 p-4
  bg-bubble-bg-l dark:bg-bubble-bg-d rounded-2xl w-auto h-[240px];
}

/* CSS Style for ROW 3*/
.bubskpi{
    @apply relative grow shrink pt-5 bg-bubble-bg-l
    dark:bg-bubble-bg-d rounded-2xl w-auto sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 h-64;
}

.bubskpibig{
    @apply relative grow shrink pt-5 bg-bubble-bg-l
    dark:bg-bubble-bg-d rounded-2xl w-auto sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 h-auto;
}

.bubskpibigblank{
    @apply relative grow shrink pt-5 rounded-2xl w-auto sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 h-auto;
}

.flexx-contnt-chart {
    @apply relative flex flex-wrap w-full h-auto ;
}




.timepicker{
    @apply relative grow shrink flex flex-col justify-center gap-y-2 p-4
  bg-bubble-bg-l dark:bg-bubble-bg-d rounded-2xl w-40 h-[260px];
}
.slider{
    @apply flex flex-row relative grow text-left text-xl font-bold
  bg-chart-bg-l dark:bg-bubble-bg-d rounded-2xl w-[520px] h-56 mt-2 pt-5 pl-3;
}

.outage-summ{
    @apply bg-kpi-bg-l dark:bg-bubble-bg-d grow rounded-2xl w-48 h-32;
}

.charts{
    @apply relative grow shrink bg-chart-bg-l dark:bg-bubble-bg-d
    rounded-2xl w-[465px] h-56 mt-2 pt-2 pl-1
}

.outage-head{
    @apply dark:text-header-txt-d text-lg font-bold mt-5;
}

.outage-foot{
    @apply text-footer-txt-l dark:text-main-txt-d text-lg font-bold mt-1;
}


/* Prime react Datatable CSS definitions & customizations */


/* .p-datatable{
    @apply bg-main-bg-l dark:bg-main-bg-d text-main-txt-l dark:text-main-txt-d
} */

/* Table Body CSS */

.p-datatable .p-datatable-tbody > tr {
    @apply relative grow shrink bg-bubble-bg-l
    dark:bg-bubble-bg-d rounded-2xl max-w-sm text-main-txt-l dark:text-main-txt-d
}

/* Table Head CSS */

.p-datatable .p-datatable-thead {
    @apply grow shrink bg-bubble-bg-l sticky
    dark:bg-bubble-bg-d rounded-2xl  text-main-txt-l dark:text-main-txt-d
}

/* Table Header CSS */

.p-datatable .p-datatable-header {
    @apply relative grow shrink bg-slate-300 dark:bg-bubble-bg-d
     text-main-txt-l dark:text-main-txt-d
}
/* Frozen column head CSS */

/* .p-datatable .p-datatable-thead > tr > th {
    @apply bg-bubble-bg-l dark:bg-bubble-bg-d
} */

/* .p-sortable-column .p-resizable-column.p-frozen-column {
    @apply bg-bubble-bg-l dark:bg-bubble-bg-d 
}
.p-sortable-column .p-resizable-column.p-frozen-column :hover{
    @apply bg-inherit  dark:text-blue-500 text-main-txt-l
} */

.p-sortable-column.p-resizable-column.p-highlight.p-reorderable-column {
    @apply bg-bubble-bg-l dark:bg-bubble-bg-d
}

.p-sortable-column.p-resizable-column.p-highlight.p-reorderable-column :hover {
    @apply bg-inherit dark:text-blue-500 text-main-txt-l
}

.p-sortable-column.p-resizable-column.p-reorderable-column {
    @apply bg-bubble-bg-l dark:bg-bubble-bg-d 
}

.p-sortable-column.p-resizable-column.p-reorderable-column :hover{
    @apply bg-inherit  dark:text-blue-500 text-main-txt-l
}

/* Sort Arrow in DataTable */

.p-sortable-column.p-resizable-column.p-reorderable-column :hover .pi-sort-alt {
    @apply block text-blue-900 dark:text-blue-500
}

.pi-sort-alt {
    @apply hidden
}

/* 
.p-column-header-content  :hover {
    @apply bg-inherit dark:text-main-txt-d text-main-txt-l
} 

.p-column-header-content  {
    @apply bg-inherit text-main-txt-d dark:text-main-txt-l
}  */

/* Filter ICON in Table Head */

.pi.pi-filter-icon.pi-filter {
    @apply text-main-txt-l dark:text-blue-500 
}

/* Circle highlighter for the Filter Indicator */
.p-column-filter-menu-button.p-column-filter-menu-button-active {
    @apply bg-sky-200 dark:bg-bubble-bg-l
}

.p-sortable-column.p-resizable-column.p-reorderable-column :hover .pi.pi-filter-icon.pi-filter  {
    @apply text-blue-900 dark:text-blue-500
}

.p-sortable-column.p-resizable-column.p-reorderable-column :hover .p-column-title {
    @apply text-blue-900 dark:text-blue-500
}

/* Column Title  CSS */

.p-column-title {
    @apply text-main-txt-l dark:text-blue-500 text-xs
}

.p-paginator.p-component.p-paginator-bottom {
    @apply bg-bubble-bg-l dark:bg-bubble-bg-d 
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
    @apply dark:text-main-txt-d text-blue-500
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon :hover {
    @apply dark:text-main-txt-d text-blue-500
}


.p-datatable .p-sortable-column .p-sortable-column-badge {
    @apply text-blue-500
}

/* .p-dropdown.p-component.p-inputwrapper.p-inputwrapper-filled {
    background-color: #EEF2FF;
    background: #EEF2FF;
} */
.p-column-filter-overlay {
    max-width: 255px;
    max-height: auto;
}

.p-button {
    color: #6366F1;
}

.p-button-label{
    font-weight: normal;
    font-size: 1rem;
}
.p-selectbutton .p-button{
    padding: 0.45rem 0.5rem;
    @apply  bg-white
    dark:bg-bubble-bg-d dark:border-white dark:text-white text-black
}

.p-selectbutton .p-button.p-highlight{
    @apply bg-button-selection-highlight
}


/* Multi Select CSS customization */

/* .p-multiselect {
    @apply bg-bubble-bg-l
    dark:bg-bubble-bg-d
} */

/* Multi-Select Cross filter Apply Button CSS */
.p-button[id="crossFilterMultiSelectButton"] {
    @apply bg-white
    dark:bg-bubble-bg-d dark:border-white
     border-slate-300 hover:bg-blue-500 dark:hover:bg-blue-500
     font-semibold
    
}

.p-multiselect .p-multiselect-label {
    padding: 0.6rem 0.5rem;
}

.chartFilter .p-multiselect .p-multiselect-label {
    padding: 0.5rem 0.4rem;
}



/* Multi-Select filter Text field CSS */
.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container  .p-inputtext{
    @apply p-0.5 bg-bubble-bg-l dark:bg-bubble-bg-d dark:text-white
}

.p-multiselect-panel .p-multiselect-items {
    @apply p-0 bg-bubble-bg-l
  }



.p-multiselect[id="columnPicker"] {
    padding: 0.1rem 0.1rem;
    @apply bg-slate-200
    dark:bg-bubble-bg-d
}


.p-multiselect-panel .p-multiselect-header {
    background: #6a90b19a;
    @apply p-2 dark:bg-bubble-bg-d bg-white
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    color: #2b4661;
    @apply bg-white font-semibold

  }

.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
    @apply p-2 bg-white dark:bg-bubble-bg-d dark:text-white
}

.chartFilter .p-multiselect .p-multiselect-label.p-placeholder {
    /* color: #6366F1; */
    @apply text-black dark:text-white
  }


.chartFilter .p-multiselect {
    @apply bg-white
    dark:bg-bubble-bg-d text-blue-600
}

.chartFilter .p-inputwrapper-filled{
    @apply bg-button-selection-highlight  dark:bg-button-selection-highlight
    text-white border-button-selection-highlight dark:border-white
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container  .p-inputtext {
    @apply bg-white  dark:bg-bubble-bg-d
}

/* Overlay Panel CSS for Cross filter selection */


.p-overlaypanel{
    @apply  bg-header-bg top-28

}
.p-overlaypanel .p-overlaypanel-content {
    @apply p-2.5
}

/* Side Bar CSS - Future use */

/* .p-sidebar-top .p-sidebar {
    @apply h-28
}
.p-sidebar .p-sidebar-header {
    @apply p-0 bg-main-bg-l dark:bg-main-bg-d
}

.p-sidebar .p-sidebar-content {
    @apply p-0 bg-main-bg-l dark:bg-main-bg-d px-5
} */


/* CSS for Porifle Initials Avatar */
.p-avatar{
    width: 2rem;
    height: 2rem;
}

/* Prime react Datatable CSS definitions & customizations - ENDS*/